import React, { useEffect, useState } from 'react'
import InventoryItems from '../Invetory/InventoryItems';
import { deleteAllProducts } from '../utils/calls';
import colors from '../globalStyles.scss'
import ConditionalRender from '../Core/ConditionalRender';
import { isEmpty } from 'lodash';
import Wrapper from '../Core/Wrapper';
import ConfirmModal from '../Core/ConfirmModal';
import { useLanguage } from '../context/LanguageContext';
import { useToast } from '../context/ToastContext';
import useScreenSize from '../context/useScreenSize';

const InventoryHomeContainerExpanded = ({
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    setInventoryColumns,
    fetchAttributes,
    isProject,
    projectId,
    projects,
    project,
    user,
    employees,
    noDataText,
    projectIsComplete
}) => {
    const [toggleModal, setToggleModal] = useState(false)
    const { text } = useLanguage();
    const { notify } = useToast();
    const { isDesktop } = useScreenSize();

    const handleDeleteAllProducts = async () => {
        try {
            const res = await deleteAllProducts();
            if (res.status === 200) {
                setToggleModal(false);
                fetchInventoryProducts(projectId || null);
                notify(text?.notificationsUI?.inventory?.allProductsDeleted, 'success');
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    useEffect(() => {
        fetchInventoryProducts(projectId || null)
    }, [])

    return (
        <>
            <Wrapper
                isLoading={inventorysLoading}
            >
                <div className='InventoryHomeContainer' style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <InventoryItems
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventoryColumns={inventoryColumns}
                        setInventoryColumns={setInventoryColumns}
                        fetchAttributes={fetchAttributes}
                        isSuperAdmin={false}
                        isProject={isProject}
                        projectId={projectId}
                        projects={projects}
                        project={project}
                        employees={employees}
                        user={user}
                        noDataText={noDataText}
                        projectIsComplete={projectIsComplete}
                        showLightColors
                        noOverlay
                    />
                    <ConditionalRender renderIf={user?.roleName === 'Admin' && !isEmpty(inventory)}>
                        <div style={{
                            color: colors.red,
                            fontFamily: colors.openSans,
                            alignSelf: 'start',
                            cursor: 'pointer'
                        }} onClick={() => setToggleModal(true)}>
                            {text?.inventory?.landing?.deleteAll}
                        </div>
                    </ConditionalRender>
                </div>

            </Wrapper>
            <ConfirmModal
                onClick={handleDeleteAllProducts}
                text={text?.inventory?.landing?.modal?.button}
                toggle={() => setToggleModal(!toggleModal)}
                isOpen={toggleModal}
                btnStyles={{
                    color: colors.red
                }}
                width={isDesktop ? '50%' : '100%'}
                height={isDesktop ? '50%' : '100%'}
            >
                <div>
                    <h4 style={{ marginBottom: '15px' }}>
                        {text?.inventory?.landing?.modal?.title}
                    </h4>
                    <p>
                        {text?.inventory?.landing?.modal?.subtitle}
                    </p>
                </div>
            </ConfirmModal>
        </>
    )
}

export default InventoryHomeContainerExpanded