import React, { useState, useEffect, useContext } from 'react'
import ConditionalRender from '../Core/ConditionalRender'
import WelcomeAdminContainer from './WelcomeAdminContainer'
import WelcomeEmployeeContainer from './WelcomeEmployeeContainer'
import { Form } from 'react-final-form';
import SelectInput from '../Core/SelectInput'
import TimeTracker from '../Core/TimeTracker'
import { isEmpty } from 'lodash';
import { TimerContext } from '../context/TimeContext';
import Wrapper from '../Core/Wrapper'
import {
    getRunningTimesheet
} from '../utils/calls'

import styled from 'styled-components'

import colors from '../globalStyles.scss'

const StyledDiv = styled.div`
    // width: 100%;
    display: flex;
    flex-direction: column;
    form {
        align-self: flex-end;
    }
`;

const WelcomeContainer = ({
    userRole,
    projects,
    user,
    company,
    fetchCompanyDetails,
    fetchCompanyEmployees,
    companyIsLoading,
    projectsIsLoading,
    employees,
    employeesIsLoading,
    inventory,
    fetchInventoryProducts,
    inventorysLoading,
    inventoryColumns,
    fetchAttributes,
    setInventoryColumns,
    handleToggleChat,
    showChat,
    handleSendMessage,
    setInventory,
    isCompanyPremium,
    fetchUserDetails,
    tasks,
    tasksIsLoading,
    fetchProjectTasks,
    fetchAllClients,
    clients,
    clientsIsLoading,
    proposals,
    proposalsIsLoading,
    fetchAllProposals,
    fetchCompanyProjects
}) => {
    const {
        isRunning,
    } = useContext(TimerContext);

    const [projectId, setProjectId] = useState('');
    const [initialProject, setInitialProject] = useState();

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('timeTracker'));

        const fetchData = async () => {
            if (isEmpty(storedData)) {
                try {
                    const res = await getRunningTimesheet();
                    if (res.status === 200) {
                        const project = projects?.find(p => p._id === res.data?.projectId);
                        setInitialProject({
                            label: project?.projectName,
                            value: project?._id
                        });
                        setProjectId(project?._id);
                    } else if (res.status === 404) {
                        // Handle 404 status: set to first project if available
                        if (projects && projects?.length > 0) {
                            setInitialProject({
                                label: projects[0]?.projectName,
                                value: projects[0]?._id
                            });
                            setProjectId(projects[0]?._id);
                        }
                    }
                } catch (error) {
                    // Handle other errors or set default project
                    if (projects && projects?.length > 0) {
                        setInitialProject({
                            label: projects[0]?.projectName,
                            value: projects[0]?._id
                        });
                        setProjectId(projects[0]?._id);
                    }
                }
            }
        };

        fetchData();
    }, [projects]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('timeTracker'));

        if (storedData?.isRunning && !isEmpty(projects)) {
            const projectId = storedData?.projectId;
            setProjectId(projectId);
            const project = projects?.find(p => p._id === projectId);
            setInitialProject({
                label: project?.projectName,
                value: project?._id
            });
        } else {
            // In case there is no running project, but we still have the projectId stored
            const projectId = storedData?.projectId;
            const project = projects?.find(p => p._id === projectId);

            // If we find the project with the stored projectId, use that, otherwise set to empty
            if (project) {
                setInitialProject({
                    label: project.projectName,
                    value: project._id
                });
                setProjectId(project?._id);

            }
        }
    }, [projects]);

    const handleChange = (e) => {
        setProjectId(e?.value)
    }

    useEffect(() => {
        fetchProjectTasks()
    }, [])

    return (
        <StyledDiv>
            <ConditionalRender
                isLoading={projectsIsLoading}
                renderIf={true}
            >

                <ConditionalRender
                    renderIf={userRole === 'Admin'}
                >
                    <WelcomeAdminContainer
                        projects={projects}
                        projectsIsLoading={projectsIsLoading}
                        projectId={projectId}
                        company={company}
                        user={user}
                        employees={employees}
                        employeesIsLoading={employeesIsLoading}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        inventoryColumns={inventoryColumns}
                        fetchAttributes={fetchAttributes}
                        setInventoryColumns={setInventoryColumns}
                        initialProject={initialProject}
                        handleChange={handleChange}
                        isRunning={isRunning}
                        fetchUserDetails={fetchUserDetails}
                        isCompanyPremium={isCompanyPremium}
                        handleToggleChat={handleToggleChat}
                        showChat={showChat}
                        handleSendMessage={handleSendMessage}
                        setInventory={setInventory}
                        userRole={userRole}
                        tasks={tasks}
                        tasksIsLoading={tasksIsLoading}
                        fetchProjectTasks={fetchProjectTasks}
                        fetchAllClients={fetchAllClients}
                        clients={clients}
                        clientsIsLoading={clientsIsLoading}
                        proposals={proposals}
                        proposalsIsLoading={proposalsIsLoading}
                        fetchAllProposals={fetchAllProposals}
                        fetchCompanyProjects={fetchCompanyProjects}
                    />
                </ConditionalRender>
                <ConditionalRender
                    renderIf={userRole !== 'Admin'}
                >
                    <WelcomeEmployeeContainer
                        projects={projects}
                        projectsIsLoading={projectsIsLoading}
                        projectId={projectId}
                        employees={employees}
                        company={company}
                        user={user}
                        inventory={inventory}
                        fetchInventoryProducts={fetchInventoryProducts}
                        inventorysLoading={inventorysLoading}
                        inventoryColumns={inventoryColumns}
                        fetchAttributes={fetchAttributes}
                        setInventoryColumns={setInventoryColumns}
                        initialProject={initialProject}
                        handleChange={handleChange}
                        isRunning={isRunning}
                        fetchUserDetails={fetchUserDetails}
                        isCompanyPremium={isCompanyPremium}
                        handleToggleChat={handleToggleChat}
                        showChat={showChat}
                        handleSendMessage={handleSendMessage}
                        setInventory={setInventory}
                        userRole={userRole}
                        tasks={tasks}
                        tasksIsLoading={tasksIsLoading}
                        fetchProjectTasks={fetchProjectTasks}
                        clients={clients}
                        clientsIsLoading={clientsIsLoading}
                        proposals={proposals}
                        proposalsIsLoading={proposalsIsLoading}
                        fetchAllProposals={fetchAllProposals}
                        fetchCompanyEmployees={fetchCompanyEmployees}
                    />
                </ConditionalRender>
            </ConditionalRender>
        </StyledDiv >
    )
}

export default WelcomeContainer