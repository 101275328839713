import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../context/GlobalContext';
import Loader from '../Core/Loader';
import ConfirmModal from '../Core/ConfirmModal';
import ConditionalRender from '../Core/ConditionalRender';
import { Form } from 'react-final-form';
import Wrapper from '../Core/Wrapper';
import FormActions from '../Core/FormActions';
import MultiFields from '../Core/MultiFields';
import Switch from '@mui/material/Switch';
import CreatableInput from '../Core/Inputs/CreatableInput';
import { composeValidators, isRequired } from '../utils/validators';
import base64ToBlob from '../utils/base64ToBlob';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useScreenSize from '../context/useScreenSize';
import CallToAction from '../Core/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faGear } from '@fortawesome/free-solid-svg-icons';
import ProfileContainer from '../Profile/ProfileContainer';
import { useTheme } from '../context/ThemeContext'
import { useLanguage } from '../context/LanguageContext';
import { Link } from 'react-router-dom';
import { useToast } from '../context/ToastContext'
import SubscriptionContainer from './SubscriptionContainer';
import { languageOptions } from '../utils/helperFunctions';
import EditableField from '../Core/Inputs/EditableField';
import ImageUploader from '../Core/ImageUploader';
import { isEmpty } from 'lodash'
import {
    deleteField,
    updateCompany,
    deleteTemplate,
    uploadCompanyLogo,
    cancelSubscription,
    updateUser,
    updateTemplate,
    updateField
} from '../utils/calls'
import colors from '../globalStyles.scss'
import FieldWrapper from '../Core/FieldWrapper';
const stripePromise = loadStripe('pk_test_4r3RNInkDMLd9uT1fNMSaqJD');


const SettingsContainer = ({
    fetchUserDetails,
    isCompanyPremium,
    fetchCompanyDetails,
    user,
    company,
    fieldNames,
    inventory,
    fetchAttributes,
    userRole,
    employees,
    projects,
    templates,
    fetchTemplates,
    templatesIsLoading,
    subscriptionIsLoading,
    subscription,
    fetchResources
}) => {
    const { fetchInventoryProducts } = useContext(GlobalContext);

    const { notify } = useToast();
    const navigate = useNavigate();
    const { isDesktop } = useScreenSize();


    const [unusedFieldNames, setUnusedFieldNames] = useState(null);
    const [usedFieldNames, setUsedFieldNames] = useState(null);
    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
    const [toggleDeleteTemplate, setToggleDeleteTemplate] = useState(false);

    const [currentItem, setCurrentItem] = useState(null);

    const [isEditing, setIsEditing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [error, setError] = useState('');

    const handleIconClick = (item, callBack) => {
        setCurrentItem(item);  // set current item when the icon is clicked
        callBack && callBack();
    }
    const { toggleTheme, isDarkMode } = useTheme();
    const { updateLanguage, language, text } = useLanguage();
    const [is2FAEnabled, setIs2FAEnabled] = useState(user?.is2FAEnabled);

    useEffect(() => {
        setIs2FAEnabled(user?.is2FAEnabled)
    }, [user?.is2FAEnabled])

    const handle2fAToggle = async () => {
        try {
            const res = await updateUser({ is2FAEnabled: !is2FAEnabled, email: user?.email });
            if (res.status === 200) {
                setIs2FAEnabled(!is2FAEnabled)
                fetchUserDetails()
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteField = async () => {
        try {
            const res = await deleteField(currentItem?.id);
            if (res.status === 200) {
                fetchAttributes();
                setToggleDeleteModal(!toggleDeleteModal);
                notify(text?.notificationsUI?.settings?.fieldDeleted, 'success')
            }
        } catch (error) {
            notify(`Error: ${error.message}`, 'error')
            console.error(error);
        }
    }

    const handleDeleteTemplate = async () => {
        const { id } = currentItem;
        try {
            const res = await deleteTemplate(id);
            if (res.status === 200) {
                fetchTemplates();
                setToggleDeleteTemplate(!toggleDeleteTemplate);
                notify(text?.notificationsUI?.settings?.templateDeleted, 'success')
            }
        } catch (error) {
            console.error(error);
            notify(`Error: ${error.message}`, 'error')
        }
    }

    useEffect(() => {
        fetchInventoryProducts()
    }, [])


    useEffect(() => {
        // This will hold all field names used in inventory
        const usedFieldNames = new Set();

        for (const product of inventory) {
            if (product.advanceFields) {  // Make sure advanceFields is not undefined
                for (const key in product.advanceFields) {
                    const fieldName = key.split('-')[1];  // Extract the field name
                    usedFieldNames.add(fieldName);
                }
            }
        }

        // Check that field.name and field.id exist before comparing them to usedFieldNames
        const unused = fieldNames.filter(field => field.name && field.id && !usedFieldNames.has(field.name));
        const used = fieldNames.filter(field => field.name && field.id && usedFieldNames.has(field.name));
        setUnusedFieldNames(unused);
        setUsedFieldNames(used);
    }, [fieldNames, inventory]);

    const handleDisabledToggle = () => {
        setDisabled(!disabled);
    };

    const onSubmit = async (values) => {
        try {
            const res = await updateCompany(user?.id, values)
            if (res.status === 200) {
                setIsEditing(false)
                setDisabled(true)
                fetchCompanyDetails(company?.id)
                notify(text?.notificationsUI?.settings?.updated, 'success')
            }
        } catch (error) {
            setError(error.message);
            notify(`Error: ${error.message}`, 'error')
            console.error(error)
        }
    }

    const required = composeValidators(
        isRequired(text?.settings?.validation?.required),
    )

    const [logoIsLoading, setLogoIsLoading] = useState(false);

    const handleCompanyLogo = async (imageData) => {
        setLogoIsLoading(true);
        try {
            const image = imageData[0];
            const imageBlob = base64ToBlob(image?.dataUrl); // Convert base64 data to a Blob
            const formData = new FormData();
            formData.append("image", imageBlob, image?.originalName); // Add the Blob to FormData, along with a file name
            formData.append("companyId", company?.id); // Add the project ID to the FormData
            console.log("Uploading image...");
            return uploadCompanyLogo(formData).then((imageRes) => {
                if (imageRes?.status === 200) {
                    console.log("Image uploaded");
                    fetchCompanyDetails(company?.id);
                    setLogoIsLoading(false);
                    notify(text?.notificationsUI?.settings?.companyLogoUpdated, 'success')
                } else {
                    setLogoIsLoading(false);
                    notify('Error', 'error')
                    throw new Error("Image upload failed");
                }
            });
        } catch (error) {
            setLogoIsLoading(false);
            console.error(error)
        }
    };

    const handleSave = async (newValue, templateId) => {
        try {
            const res = await updateTemplate(templateId, { templateName: newValue });
            if (res.status === 200) {
                fetchTemplates();
                notify('Template name updated', 'success')
            }
        } catch (error) {
            console.error(error)
        }
    };

    const handleUpdateField = async (newValue, fieldId) => {
        const values = {
            label: newValue,
            fieldLabel: newValue
        }
        try {
            const res = await updateField(fieldId, values);
            if (res.status === 200) {
                fetchAttributes();
                notify('Field label updated', 'success')
            }
        } catch (error) {
            console.error(error)
        }

    }

    const handleCancel = () => {
        console.log('Edit canceled');
    };

    if (!unusedFieldNames && !usedFieldNames) return <Loader />

    return (
        <>
            {/* Profile */}
            <ProfileContainer
                fetchUserDetails={fetchUserDetails}
                user={user}
                company={company}
                userRole={userRole}
                isCompanyPremium={isCompanyPremium}
            />

            {/* Settings */}
            <div className='flex Settings' style={{
                // background: colors.darkGray,
                padding: '10px',
                flexDirection: 'column',
                marginBottom: '15px'
            }}>
                <div style={{
                    display: 'flex',
                    alignSelf: 'end',
                    alignItems: 'center'
                }}>
                    <h4>
                        {text?.settings?.title}
                    </h4>
                    <FontAwesomeIcon
                        style={{
                            marginRight: '15px',
                            marginLeft: '15px',
                            fontSize: '3rem',
                        }}
                        icon={faGear}
                    />
                </div>

                {/* Only admins can remove fields */}
                <ConditionalRender renderIf={true}>

                    <ConditionalRender renderIf={false}>
                        {/* <ConditionalRender renderIf={user?.roleName === 'Admin'}> */}
                        {/* Edit Company Name */}
                        <div className='flex'>
                            <div
                                className='flex'
                                style={{
                                    flex: '1',
                                    flexDirection: 'column',
                                }}>
                                <div style={{
                                    alignSelf: 'center'
                                }}>
                                    <Switch
                                        checked={!disabled}
                                        onChange={handleDisabledToggle}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <span style={{ fontFamily: colors.openSans, color: disabled ? colors.black : colors.blue }}>{disabled ? "Edit Off" : "Edit On"}</span>
                                </div>
                            </div>
                        </div>
                    </ConditionalRender>

                    <div className='flex'>
                        <div className='flex-one'>
                            <Form
                                onSubmit={onSubmit}
                                keepDirtyOnReinitialize
                                keepValuesOnReinitialize
                                initialValue={{
                                    companyName: company?.companyName,
                                    companyPhone: company?.companyPhone,
                                    companyAddress: company?.companyAddress,
                                }}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <ConditionalRender renderIf={userRole === 'Admin'}>
                                            <MultiFields
                                                className='field-style'
                                                name="companyName"
                                                label={text?.settings?.fields?.companyName}
                                                validate={required}
                                                initialValue={company?.companyName}
                                                component="input"
                                                parse={value => value === '' ? '' : value}
                                                type="text"
                                                setIsEditing={setIsEditing}
                                                block
                                            />
                                            <MultiFields
                                                className='field-style'
                                                name="companyPhone"
                                                component="phone"
                                                type="text"
                                                label={text?.settings?.fields?.companyPhone}
                                                block
                                                initialValue={company?.companyPhone}
                                                setIsEditing={setIsEditing}
                                            />

                                            <MultiFields
                                                className='field-style'
                                                name="companyAddress"
                                                component="location"
                                                label={text?.client?.create?.address}
                                                block
                                                initialValue={company?.companyAddress}
                                                setIsEditing={setIsEditing}
                                                showMapLabel={text?.client?.create?.showMapLabel}
                                            />

                                        </ConditionalRender>

                                        {
                                            (isEditing) && (
                                                <FormActions
                                                    type="submit"
                                                    submitText={text?.settings?.button}
                                                />
                                            )
                                        }
                                    </form>
                                )}
                            />
                        </div>
                    </div>

                    {/* Company Logo */}
                    <ConditionalRender isLoading={logoIsLoading} renderIf={isCompanyPremium && userRole === 'Admin'}>
                        <div className='companyLogo mb-md'>

                            <h4 className='mb-md' style={{ textAlign: 'left' }}>
                                {text?.settings?.companyLogo?.title}
                            </h4>
                            <p className='mb-md' style={{ textAlign: 'left' }}>
                                {text?.settings?.companyLogo?.info}
                            </p>
                            <div className='flex items-center'>
                                <img className='mr-md' height='100' src={company?.companyLogo} alt={`${company?.companyName} logo`} />
                                <ImageUploader
                                    onImageUpload={handleCompanyLogo}
                                    maxFiles={1}
                                    customMessage={text?.settings?.companyLogo?.uploadMessage}
                                    noHeader
                                />
                            </div>
                        </div>
                    </ConditionalRender>

                    <div className='flex align-center '>
                        <Switch
                            checked={isDarkMode}
                            onChange={toggleTheme}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <span style={{ fontFamily: colors.openSans }}>{isDarkMode ? text?.settings?.mode?.dark : text?.settings?.mode?.light}</span>
                    </div>
                    <ConditionalRender renderIf={user?.isAccountVerified}>
                        <div className='flex flex-column align-items-start mt-md mb-md'>
                            <p>
                                {text?.settings?.twoFactorAuth?.info}
                            </p>
                            <p>
                                {text?.settings?.twoFactorAuth?.infoTwo}
                            </p>
                            <div className='flex items-center'>
                                <Switch
                                    checked={is2FAEnabled}
                                    onChange={handle2fAToggle}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <span style={{ fontFamily: colors.openSans }}>{is2FAEnabled ? text?.settings?.twoFactorAuth?.on : text?.settings?.twoFactorAuth?.off}</span>
                            </div>
                        </div>
                    </ConditionalRender>
                    <div className='mb-md'>
                        <CreatableInput
                            initValue={
                                languageOptions.find((x) => x.value === language)?.label
                            }
                            isSearchable={false}
                            isClearable={false}
                            name="selectedLanguage"
                            onChange={(event) => {
                                updateLanguage(event.value, true);
                                fetchAttributes()
                            }}
                            options={languageOptions}
                        />
                    </div>

                    <ConditionalRender renderIf={isCompanyPremium}>
                        <ConditionalRender renderIf={!isEmpty(unusedFieldNames) || !isEmpty(templates?.filter((x) => x.templateName !== 'Default Template Name'))}>
                            <Wrapper
                                title={text?.projects?.create?.inventory}
                            >
                                {/* Templates */}
                                <ConditionalRender loadingCount={templates.length} isLoading={templatesIsLoading} renderIf={!isEmpty(templates?.filter((x) => x?.templateName !== 'Default Template Name'))}>
                                    <h5
                                        className='flex'
                                        style={{
                                            fontFamily: colors.openSans,
                                            marginBottom: '15px'
                                        }}>
                                        {text?.settings?.templates?.title}
                                    </h5>
                                    <div className='flex mb-md' style={{
                                        flexDirection: 'column',
                                        alignItems: 'start'
                                    }}>
                                        {
                                            templates?.filter((x) => x.templateName !== 'Default Template Name')?.map((x) => {
                                                return (
                                                    <div style={{ margin: '5px' }} key={x.id}>
                                                        <div className='flex items-center'>
                                                            <ConditionalRender renderIf={userRole === 'Admin' || x?.createdBy === user?.id}>
                                                                <FontAwesomeIcon
                                                                    onClick={() => handleIconClick(x, () => setToggleDeleteTemplate(!toggleDeleteTemplate))}
                                                                    style={{
                                                                        color: colors.red,
                                                                        marginRight: '15px',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    icon={faTrashAlt}
                                                                />
                                                            </ConditionalRender>
                                                            <EditableField
                                                                // type={Types.TEXT}
                                                                value={x?.templateName}
                                                                onSave={(value) => handleSave(value, x?.id)}
                                                                onCancel={handleCancel}
                                                                placeholder="Enter your name"
                                                                allowEdit={userRole === 'Admin' || x?.createdBy === user?.id}
                                                            />
                                                        </div>
                                                        <ConfirmModal
                                                            onClick={() => handleDeleteTemplate(x)}
                                                            text={text?.settings?.templates?.modal?.button}
                                                            toggle={() => setToggleDeleteTemplate(!toggleDeleteTemplate)}
                                                            isOpen={toggleDeleteTemplate}
                                                            btnStyles={{ color: colors.red }}
                                                            width={isDesktop ? '50%' : '100%'}
                                                            height={isDesktop ? '50%' : '100%'}
                                                        >
                                                            {text?.settings?.templates?.modal?.title}
                                                        </ConfirmModal>
                                                    </div>
                                                )

                                            })
                                        }
                                    </div>
                                </ConditionalRender>
                                {/* Unused Fields */}
                                <ConditionalRender renderIf={!isEmpty(unusedFieldNames || !isEmpty(usedFieldNames))}>
                                    <h5
                                        className='flex'
                                        style={{
                                            fontFamily: colors.openSans,
                                            marginBottom: '15px'
                                        }}>
                                        {text?.settings?.fields?.title}
                                    </h5>
                                </ConditionalRender>
                                <ConditionalRender
                                    renderIf={
                                        !isEmpty(unusedFieldNames)
                                    }
                                >
                                    <div className='flex' style={{
                                        flexDirection: 'column',
                                        alignItems: 'start'
                                    }}>
                                        {
                                            unusedFieldNames?.map((x, index) => (
                                                <div style={{ margin: '5px' }} key={index}>
                                                    <div className='flex items-center'>
                                                        <FontAwesomeIcon
                                                            onClick={() => handleIconClick(x, () => setToggleDeleteModal(!toggleDeleteModal))}
                                                            style={{
                                                                color: colors.red,
                                                                marginRight: '15px',
                                                                cursor: 'pointer'
                                                            }}
                                                            icon={faTrashAlt}
                                                        />
                                                        <EditableField
                                                            // type={Types.TEXT}
                                                            value={x?.label}
                                                            onSave={(value) => handleUpdateField(value, x?.id)}
                                                            onCancel={handleCancel}
                                                        />
                                                    </div>
                                                    <ConfirmModal
                                                        onClick={() => handleDeleteField(x)}
                                                        text={text?.settings?.fields?.modal?.button}
                                                        toggle={() => setToggleDeleteModal(!toggleDeleteModal)}
                                                        isOpen={toggleDeleteModal}
                                                        btnStyles={{ color: colors.red }}
                                                        width={isDesktop ? '50%' : '100%'}
                                                        height={isDesktop ? '50%' : '100%'}
                                                    >
                                                        {text?.settings?.fields?.modal?.title}
                                                    </ConfirmModal>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </ConditionalRender>
                                {/* Used Fields */}
                                <ConditionalRender
                                    renderIf={
                                        !isEmpty(usedFieldNames)
                                    }
                                >
                                    <div className='flex' style={{
                                        flexDirection: 'column',
                                        alignItems: 'start'
                                    }}>
                                        {
                                            usedFieldNames?.map((x, index) => (
                                                <div style={{ margin: '5px' }} key={index}>
                                                    <div className='flex items-center'>
                                                        <EditableField
                                                            // type={Types.TEXT}
                                                            value={x?.label}
                                                            onSave={(value) => handleUpdateField(value, x?.id)}
                                                            onCancel={handleCancel}
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </ConditionalRender>
                            </Wrapper>
                        </ConditionalRender>
                    </ConditionalRender>
                </ConditionalRender>
                <ConditionalRender renderIf={
                    isEmpty(subscription) &&
                    company?.accessGranted && userRole === 'Admin'
                }>
                    <ConditionalRender renderIf={!company?.hasFreeAccess}>

                        <Link to="/membership">{text?.subscription?.goPremium}</Link>
                    </ConditionalRender>
                </ConditionalRender>
            </div>

            <hr />

            <ConditionalRender renderIf={!isEmpty(subscription) && userRole === 'Admin'} isLoading={subscriptionIsLoading}>
                <ConditionalRender renderIf={!company?.hasFreeAccess}>
                    {/* Subscription */}
                    <Elements stripe={stripePromise} options={{ locale: language }} >
                        <SubscriptionContainer
                            isCompanyPremium={isCompanyPremium}
                            userRole={userRole}
                            subscription={subscription}
                            user={user}
                            company={company}
                            fetchCompanyDetails={fetchCompanyDetails}
                            fetchUserDetails={fetchUserDetails}
                        />
                    </Elements>
                </ConditionalRender>
            </ConditionalRender>

            <div>
                <FieldWrapper
                    name={'Version'}
                    label={'1.27.0 (Beta)'}
                />
            </div>
        </>
    )
}

export default SettingsContainer