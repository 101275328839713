import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import NotificationsContainer from '../Notifications/NotificationsContainer';
import { WebSocketProvider } from '../context/WebSocketContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import colors from '../globalStyles.scss';
import NavLink from './NavLink';
import { btnUnderline, media } from '../mixins';
import { useLanguage } from '../context/LanguageContext';
import logo from '../images/logo.png';
import useScreenSize from '../context/useScreenSize';
import Footer from '../Footer/Footer';
import './styles.scss';
import ConditionalRender from '../Core/ConditionalRender';

const StyledNavigation = styled.div`
  background: ${colors.black};
  width: 100%;
  height: 5%;
  position: fixed;
  z-index: 1;
  .navigation {
    padding: 0 10px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 100%;
      img {
        object-fit: contain;
        height: 100%;
      }
      .site-name {
        color: ${colors.white};
        font-family: ${colors.openSans};
        font-size: 1em;
        margin-left: 10px;
      }
    }
    .menu-toggle {
      cursor: pointer;
      color: ${colors.white};
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      list-style: none;
      width: 100%;
      li {
        position: relative;
        height: 100%;
        cursor: pointer;
        margin: 0 15px;
        color: ${colors.white};
        font-family: ${colors.roboto};
        a {
          color: ${colors.white};
          font-family: ${colors.roboto};
          height: 100%;
        }
        ${btnUnderline(colors.white)}
      }
      li.logout {
        ${btnUnderline(colors.white)}
      }
    }
    .beta {
      color: ${colors.blue};
      font-family: ${colors.openSans};
    }
  }

  .full-screen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.black};
    z-index: 10000000;
    .logo-container {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-left: 10px;
      height: 100%;
      width: 100%;
      img {
        object-fit: contain;
        height: 30px;
      }
      .site-name {
        color: ${colors.white};
        font-family: ${colors.openSans};
        font-size: 1em;
        margin-left: 10px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 1em;
      margin-bottom: 1em;
      list-style: none;
      li {
        margin: 0.5em 0;
        font-size: 1em;
        font-family: ${colors.roboto};
        color: ${colors.white};
        a {
          color: ${colors.white};
          font-family: ${colors.roboto};
        }
      }
    }
    .close-icon {
      font-size: 2em;
      cursor: pointer;
      color: ${colors.white};
      margin-right: 10px;
    }
  }
`;

const Navigation = ({
  isLoggedIn,
  logoutUser,
  isCompanyPremium,
  user,

  handleToggleChat,
  showChat,
  handleSendMessage,
  conversationHistory,
  thinking,
  chatErrorMessage,
  isSuperAdmin,
  typing,
  handleLinkClick,
  toggleMenu,
  menuActive,
  setCollapsed
}) => {
  const navigate = useNavigate();
  const { text } = useLanguage();
  const { isDesktop, isTablet, isPhone } = useScreenSize();

  return (
    <StyledNavigation>
      <div className='navigation'>
        <div className='logo-container' onClick={() => navigate('/')}>
          <img src={logo} alt='logo' />
          <span className='site-name'>{'ArdidFlow.com'}</span>
        </div>
        {(isPhone || isTablet) && (
          <div className='flex items-center justify-between'>
            <ConditionalRender renderIf={(isLoggedIn && isCompanyPremium)}>
              <FontAwesomeIcon
                onClick={() => navigate('/calendar')}
                icon={faCalendarAlt} style={{
                  color: colors.white,
                  cursor: 'pointer',
                  marginRight: '1em'
                }}
              />
              <WebSocketProvider userId={user?._id}>
                <NotificationsContainer setCollapsed={setCollapsed} user={user} userId={user?._id} />
              </WebSocketProvider>
            </ConditionalRender>
            <div className='menu-toggle ml-md' onClick={toggleMenu}>
              ☰
            </div>
          </div>
        )}
        <ul style={{ display: isDesktop ? 'flex' : 'none' }}>
          <NavLink to="/" onClick={handleLinkClick}>{(isLoggedIn && isCompanyPremium) ? text?.navigation?.dashboard : text?.navigation?.home}</NavLink>
          {!isLoggedIn ? (
            <>
              <NavLink to="/register" onClick={handleLinkClick}>{text?.navigation?.register}</NavLink>
              <NavLink to="/login" onClick={handleLinkClick}>{text?.navigation?.login}</NavLink>
            </>
          ) : (
            <>
              <NavLink logoutUser={logoutUser} onClick={handleLinkClick}>
                {text?.navigation?.logout}
              </NavLink>
              <ConditionalRender renderIf={(isLoggedIn && isCompanyPremium)}>
                <FontAwesomeIcon
                  onClick={() => navigate('/calendar')}
                  icon={faCalendarAlt} style={{
                    color: colors.white,
                    cursor: 'pointer',
                    marginRight: '1em'
                  }}
                />
              </ConditionalRender>
              <ConditionalRender renderIf={(isLoggedIn && isCompanyPremium)}>
                <WebSocketProvider userId={user?._id}>
                  <NotificationsContainer user={user} userId={user?._id} />
                </WebSocketProvider>
              </ConditionalRender>
            </>
          )}
        </ul>
      </div>
      {menuActive && (
        <div className='full-screen-menu'>
          <div className='flex items-center justify-space-between'>
            <div className='logo-container' onClick={() => navigate('/')}>
              <img src={logo} alt='logo' />
              <span className='site-name'>{'ArdidFlow.com'}</span>
            </div>
            <div className='close-icon' onClick={toggleMenu}>
              &times;
            </div>
          </div>

          <div className='h-100'>
            <ul>
              <NavLink to="/" onClick={handleLinkClick}>{(isLoggedIn && isCompanyPremium) ? text?.navigation?.dashboard : text?.navigation?.home}</NavLink>
              {!isLoggedIn ? (
                <>
                  <NavLink to="/register" onClick={handleLinkClick}>{text?.navigation?.register}</NavLink>
                  <NavLink to="/login" onClick={handleLinkClick}>{text?.navigation?.login}</NavLink>
                </>
              ) : (
                <>
                  <NavLink logoutUser={logoutUser} onClick={handleLinkClick}>
                    {text?.navigation?.logout}
                  </NavLink>
                  <ConditionalRender renderIf={isDesktop}>
                    <WebSocketProvider userId={user?._id}>
                      <NotificationsContainer user={user} userId={user?._id} />
                    </WebSocketProvider>
                  </ConditionalRender>
                </>
              )}
            </ul>
            <Footer
              handleToggleChat={handleToggleChat}
              showChat={showChat}
              isCompanyPremium={isCompanyPremium}
              isLoggedIn={isLoggedIn}
              handleSendMessage={handleSendMessage}
              conversationHistory={conversationHistory}
              thinking={thinking}
              chatErrorMessage={chatErrorMessage}
              isSuperAdmin={isSuperAdmin}
              typing={typing}

              handleLinkClick={handleLinkClick}
              toggleMenu={toggleMenu}
              menuActive={menuActive}
              isAdmin={user?.roleName === 'Admin'}

            />
          </div>
        </div>
      )}
    </StyledNavigation>
  );
};

export default Navigation;
